import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { isMobile } from "@/utils/commonUtils";
Vue.use(ElementUI);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  // debugger;

  if (to.path === "/") {
    if (isMobile()) {
      //如果是移动端
      next({ path: "/phone-home" });
    } else {
      next();
    }
  } else if (to.path === "/404") {
    next();
  } else {
    if (!isMobile() && to.path.indexOf("/phone") === 0) {
      next({ path: "/404" });
    } else if (isMobile() && to.path.indexOf("/phone") < 0) {
      next({ path: "/404" });
    } else {
      next();
    }
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
