// 公共工具类方法文件

/**
 * 将带有parentId的具有树级层次结构的平铺数据构建成带有children的树级数据
 * @param {*} data 平铺的具有parentId的数据
 * @returns 返回一个数组,具有树级层次结构
 */
const constructTree = (data) => {
  const result = [];
  if (!Array.isArray(data)) {
    return result;
  }
  data.forEach((item) => {
    delete item.children;
  });
  const map = {};
  data.forEach((item) => {
    map[item.id] = item;
  });
  data.forEach((item) => {
    const parent = map[item.parentId];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      result.push(item);
    }
  });
  return result;
};

/**
 * 深度遍历树结构查找目标节点及缓存相关路径
 * @param {*} tree 树级结构数据
 * @param {*} propery 要查找的属性名
 * @param {*} value 要查找的属性名对应的值
 * @returns 对象，包含path 查找到节点的一个路径数组;node 查找到的节点数据
 */
const findNodeAndPath = (tree, propery, value) => {
  // 逻辑判断里要求tree是个根节点不是数组，所以需要判断一下，传入的tree是个数组,则从新构建成一个根节点
  if (Array.isArray(tree)) {
    tree = { id: "root", children: tree };
  }
  if (tree[propery] === value) {
    return {
      path: [],
      node: tree,
    };
  }

  let res;
  if (tree.children && tree.children.length > 0) {
    for (let i = 0; i < tree.children.length; i++) {
      res = findNodeAndPath(tree.children[i], propery, value);
      if (res !== undefined) {
        res.path.unshift(tree.children[i].id);
        return res;
      }
    }
  }

  return undefined;
};

/**
 * 以当前日期为基础,像前/后 推 day 天后的日期
 * @param {*} day 数字类型，前推/后推第几天
 * @returns
 */
function getComputedDay(day) {
  const today = new Date();
  const day_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(day_milliseconds);
  return dateFormat(today, "yyyy-MM-dd");
}
/**
 * 根据开始时间和结束时间 获取这个时间段内的每一天
 * @param {*} start  开始日期字符串 如：2012-01-02
 * @param {*} end   结束日期字符串 如：2012-03-02
 * @returns 日期字符串数组
 */
const getAllDate = (start, end) => {
  const dateArr = [];
  const db = new Date(start + " 00:00:00");
  const de = new Date(end + " 00:00:00");
  const unixDb = db.getTime();
  const unixDe = de.getTime();
  let stamp;
  const oneDay = 24 * 60 * 60 * 1000;
  for (stamp = unixDb; stamp <= unixDe; ) {
    dateArr.push(dateFormat(new Date(parseInt(stamp)), "yyyy-MM-dd"));
    stamp = stamp + oneDay;
  }
  return dateArr;
};

/**
 * 基于约定的日期对象字符串格式化
 * @param {Date} date 要格式化的日期对象
 * @param {string} format 格式字符串
 */
function dateFormat(date = new Date(), format = "yyyy-MM-dd HH:mm:ss") {
  const fullYear = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const dateDay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minute =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const second =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  const week = ["日", "一", "二", "三", "四", "五", "六"][date.getDay()];
  let ret = format;
  ret = ret.replace(/yyyy/g, fullYear);
  ret = ret.replace(/MM/g, month);
  ret = ret.replace(/dd/g, dateDay);
  ret = ret.replace(/HH/g, hour);
  ret = ret.replace(/mm/g, minute);
  ret = ret.replace(/ss/g, second);
  ret = ret.replace(/w/g, week);
  return ret;
}

/**
 * 生成36位随机数uuid
 */
function uuid() {
  const s = [];
  const hexDigits = "0123456789abcdef";
  // eslint-disable-next-line no-const-assign
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  const uuid = s.join("");
  return uuid;
}

/**
 * 递归遍历特定列表下的特定属性
 * @param {Array} target 递归遍历的起点数组
 * @param {String} prop 存在递归的属性，对应条目下的字段
 * @param {Function} cb 回调处理函数提供当前条目和父级条目的引用，返回指定的父级对象
 * @param {Object} parent 父级引用，可能未空
 */
function recursiveTraversal(
  target = [],
  prop = "children",
  cb = () => {},
  parent
) {
  if (target) {
    for (let i = 0; i < target.length; i += 1) {
      if (target[i]) {
        const p = cb(target[i], parent);
        if (target[i][prop]?.length > 0) {
          recursiveTraversal(target[i][prop], prop, cb, p || target[i]);
        }
      }
    }
  }
}

/**
 * 深度优先的后续遍历
 * @param {Array} target 递归遍历的起点数组
 * @param {String} prop 存在递归的属性，对应条目下的字段
 * @param {Function} cb 回调处理函数提供当前条目和父级条目的引用，返回指定的父级对象
 * @param {Object} parent 父级引用，可能未空
 */
function postorderTraversal(
  target = [],
  prop = "children",
  cb = () => {},
  parent
) {
  if (target) {
    for (let i = 0; i < target.length; i += 1) {
      if (target[i]) {
        if (target[i][prop]?.length) {
          postorderTraversal(target[i][prop], prop, cb, target[i]);
        }
        cb(target[i], parent);
      }
    }
  }
}

/**
 * 三位分节法（Three position segmentation method）数字展示格式
 * @param {Number} num 要处理的数字
 * @returns {String}
 */
function tpsmNumberStr(num) {
  if (num >= 1000) {
    const strify = num.toString();
    const strifyArr = strify.split(".");
    let temp = "",
      count = 0;
    for (let i = strifyArr[0].length - 1; i >= 0; i -= 1) {
      count += 1;
      if (count === 3 && i !== 0) {
        count = 0;
        temp = "," + strifyArr[0][i] + temp;
      } else {
        temp = strifyArr[0][i] + temp;
      }
    }
    strifyArr[0] = temp;
    return strifyArr.join(".");
  } else {
    return num.toString();
  }
}

/**
 * x坐标label换行函数
 */
const commissionFormatter = (provideNumber, trim = false) => {
  return (params) => {
    let newParamsName = ""; // 最终拼接成的字符串
    const paramsNameNumber = params.length; // 实际标签的个数
    const rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
    // 条件等同于rowNumber>1
    if (paramsNameNumber > provideNumber) {
      if (trim) {
        params = params.replace(" ", "");
      }
      /** 循环每一行,p表示行 */
      for (let p = 0; p < rowNumber; p++) {
        let tempStr = ""; // 表示每一次截取的字符串
        const start = p * provideNumber; // 开始截取的位置
        const end = start + provideNumber; // 结束截取的位置
        // 此处特殊处理最后一行的索引值
        if (p === rowNumber - 1) {
          // 最后一次不换行
          tempStr = params.substring(start, paramsNameNumber);
        } else {
          // 每一次拼接字符串并换行
          tempStr = params.substring(start, end) + "\n";
        }
        newParamsName += tempStr; // 最终拼成的字符串
      }
    } else {
      // 将旧标签的值赋给新标签
      newParamsName = params;
    }
    // 将最终的字符串返回
    return newParamsName;
  };
};

/**
 * 字节数据单位转换
 * @param {number} count 待转数据
 * @param {string} from 待转数据单位
 * @param {string} to 目标数据单位
 * @param {number} accuracy 自然数，目标数据要保留的小数点精度
 * @returns {number} 计算后的值
 */
function byteUnit(count, from, to, accuracy) {
  if (typeof count !== "number") {
    throw new TypeError("expect number");
  }
  if (count < 0) {
    throw new Error("illegal value range");
  }
  if (accuracy < 0) {
    throw new Error("illegl accuracy");
  }

  const avArr = ["b", "B", "K", "M", "G", "T", "P", "E"];
  const fromI = avArr.indexOf(from);
  const toI = avArr.indexOf(to);
  const derta = fromI - toI;

  if (fromI < 0 || toI < 0) {
    throw new Error(
      'unavailable unites, it mast be one of the "b B K M G T P E"'
    );
  }

  if (derta === 0) {
    // 同级
    return count;
  } else if (derta > 0) {
    // 大转小
    // let factor;
    const factor =
      toI === 0 ? Math.pow(2, 10 * (derta - 1)) * 8 : Math.pow(2, 10 * derta);
    return (count * factor).toFixed(accuracy || 0);
  } else {
    // 小转大
    // let divisor;
    const divisor =
      fromI === 0
        ? Math.pow(2, 10 * Math.abs(derta - 1)) / 8
        : Math.pow(2, 10 * Math.abs(derta));
    return (count / divisor).toFixed(accuracy || 0);
  }
}

/**
 * 根据给入的字节数，返回一个合适的单位字符
 * @param {Number} byte 要计算的Byte值
 * @returns {String} 单位字符
 */
function getProperUnitByByte(byte) {
  const unitArry = ["B", "K", "M", "G", "T", "P", "E"];
  let index = 0;
  while (byte > 1024) {
    index++;
    byte = byte / Math.pow(2, 10);
  }
  return unitArry[index];
}

/**
 * 将给入的时间值按照给入的单位换算成目标时间值
 * @param {Number} duration 给入的时间值
 * @param {String} from 给入值的时间单位
 * @param {String} to 目标值的时间单位
 * @returns {Number} 结果
 */
function timeUnit(duration, from, to) {
  if (typeof duration !== "number") {
    throw new TypeError("expect number");
  }
  if (duration < 0) {
    throw new Error("illegal value range");
  }
  const unitArr = ["ms", "s", "m", "h", "D", "W"]; // 毫秒 秒 分 时 天 周
  if (unitArr.indexOf(from) < 0 || unitArr.indexOf(to) < 0) {
    throw new Error('unavailable unites, it mast be one of the "ms s m h D W"');
  }
  let tempMs = 0;
  switch (from) {
    case "ms":
      tempMs = duration;
      break;
    case "s":
      tempMs = duration * 1000;
      break;
    case "m":
      tempMs = duration * 60 * 1000;
      break;
    case "h":
      tempMs = duration * 60 * 60 * 1000;
      break;
    case "D":
      tempMs = duration * 24 * 60 * 60 * 1000;
      break;
    case "W":
      tempMs = duration * 7 * 24 * 60 * 60 * 1000;
      break;
    default:
      break;
  }
  let ret = 0;
  switch (to) {
    case "ms":
      ret = tempMs;
      break;
    case "s":
      ret = tempMs / 1000;
      break;
    case "m":
      ret = tempMs / 1000 / 60;
      break;
    case "h":
      ret = tempMs / 60 / 60 / 1000;
      break;
    case "D":
      ret = tempMs / 24 / 60 / 60 / 1000;
      break;
    case "W":
      ret = tempMs / 7 / 24 / 60 / 60 / 1000;
      break;
    default:
      break;
  }
  return ret;
}

/**
 * 比较ipv4地址大小，返回特定格式结果
 * @param {String} startIp 符合ip格式的起始ip地址
 * @param {String} endIp 符合ip格式的结束ip地址
 * @returns {Number} 比较结果，0: 相等, 1: 起始大于结束, -1: 结束大于起始
 */
function compareIp(startIp, endIp) {
  const temp1 = startIp.split(".");
  const temp2 = endIp.split(".");
  for (var i = 0; i < 4; i++) {
    if (Number(temp1[i]) > Number(temp2[i])) {
      return 1;
    } else if (Number(temp1[i]) < Number(temp2[i])) {
      return -1;
    }
  }
  return 0;
}

/**
 * 防抖
 * @param {} fn
 * @param {*} delay
 * @returns
 */
function debounce(fn, delay) {
  let timer = null;
  return () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(fn, delay);
  };
}

// localstorage扩展，localstorage是永久性的，此方法增加有效期设置,默认1天
const extendLocalStorage = {
  setItem: function (key, value, ttl_ms = 24 * 60 * 60 * 1000) {
    const data = {
      value: value,
      startTime: new Date().getTime(),
      expirse: ttl_ms,
    };
    localStorage.setItem(key, JSON.stringify(data));
  },
  getItem: function (key) {
    const data = JSON.parse(localStorage.getItem(key));
    if (data !== null) {
      const expireTime = new Date().getTime() - data.startTime;
      if (expireTime > data.expirse) {
        localStorage.removeItem(key);
      } else {
        return data.value;
      }
    }
    return null;
  },
  clear: function () {
    localStorage.clear();
  },
};

//弱密码校验：不能包含按键盘排列顺序相邻的字符，不区分大小写，（3个或3个连续以上）
function isKeyBoardContinuousChar(password) {
  let str = password.toLocaleLowerCase();
  // 注意，下面的键盘字符表都只列出小写字符，判断前会将输入字符中的
  // 大写字母都转换成小写字母
  // 非shift键盘字符表
  let c1 = [
    ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+"],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "{", "}", "|"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l", ":", '"'],
    ["z", "x", "c", "v", "b", "n", "m", "<", ">", "?"],
  ];
  let c2 = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "="],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "[", "]", "\\"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l", ";", "'"],
    ["z", "x", "c", "v", "b", "n", "m", ",", ".", "/"],
  ];
  str = str.split("");
  //获取坐标位置
  let y = [];
  let x = [];
  for (let c = 0; c < str.length; c++) {
    y[c] = 0; //当做~`键处理
    x[c] = -1;
    for (let i = 0; i < c1.length; i++) {
      for (let j = 0; j < c1[i].length; j++) {
        if (str[c] == c1[i][j]) {
          y[c] = i;
          x[c] = j;
        }
      }
    }
    if (x[c] != -1) continue;
    for (let i = 0; i < c2.length; i++) {
      for (let j = 0; j < c2[i].length; j++) {
        if (str[c] == c2[i][j]) {
          y[c] = i;
          x[c] = j;
        }
      }
    }
  }
  //匹配坐标连线
  for (var c = 1; c < str.length - 1; c++) {
    if (y[c - 1] == y[c] && y[c] == y[c + 1]) {
      if (
        (x[c - 1] + 1 == x[c] && x[c] + 1 == x[c + 1]) ||
        (x[c + 1] + 1 == x[c] && x[c] + 1 == x[c - 1])
      ) {
        return true;
      }
    } else if (x[c - 1] == x[c] && x[c] == x[c + 1]) {
      if (
        (y[c - 1] + 1 == y[c] && y[c] + 1 == y[c + 1]) ||
        (y[c + 1] + 1 == y[c] && y[c] + 1 == y[c - 1])
      ) {
        return true;
      }
    }
  }
  return false;
}
//弱密码校验: 密码中不能包含有连续3位及以上重复字符，字母不区分大小写(如：密码中不能包含888、aaa 或AAA或Bbb或 $$$等)。
function isPasswordContinuous(password) {
  let str = password.toLocaleLowerCase();
  let fourOrMoreSame = false;
  for (var i = 0; i < str.length - 2; i++) {
    if (
      str.charAt(i) === str.charAt(i + 1) &&
      str.charAt(i + 1) === str.charAt(i + 2)
    ) {
      fourOrMoreSame = true;
    }
  }
  return fourOrMoreSame;
}

//判断浏览器是手机还是PC
function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

export {
  constructTree,
  findNodeAndPath,
  dateFormat,
  uuid,
  recursiveTraversal,
  postorderTraversal,
  tpsmNumberStr,
  getComputedDay,
  getAllDate,
  commissionFormatter,
  byteUnit,
  getProperUnitByByte,
  timeUnit,
  compareIp,
  extendLocalStorage,
  debounce,
  isKeyBoardContinuousChar,
  isPasswordContinuous,
  isMobile,
};
