import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@views/pc/home"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@views/pc/system/login-page"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@views/pc/system/register-page"),
  },
  {
    path: "/station-introduce",
    name: "station-introduce",
    component: () => import("@views/pc/station-introduce"),
  },
  {
    path: "/upload-testing-service",
    name: "upload-testing-service",
    component: () => import("@views/pc/upload-testing-service"),
  },
  {
    path: "/phone-home",
    name: "phone-home",
    component: () => import("@views/phone/home"),
  },
  {
    path: "/phone-station-introduce",
    name: "/phone-station-introduce",
    component: () => import("@views/phone/station-introduce"),
  },
  {
    path: "/phone-upload-testing-service",
    name: "phone-upload-testing-service",
    component: () => import("@views/phone/upload-testing-service"),
  },
  {
    path: "/phone-login-page",
    name: "phone-login-page",
    component: () => import("@views/phone/system/login-page"),
  },
  {
    path: "/phone-register-page",
    name: "phone-login-page",
    component: () => import("@views/phone/system/register-page"),
  },
  {
    path: "/404",
    name: "PageError",
    component: () => import("@/components/404"),
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
